// App.js

import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/login/login';
import Signup from './components/signup/signup';
import {
  OrganisationList,
  OrganisationCreate,
} from './components/organisation';
import ManagedToolsDashboard from './components/dashboard/managed-tools-dashboard';
import { AuthProvider } from './components/login/auth-provider';
import { NotificationProvider } from './components/notification/notification-provider';
import themeData from './theme.json';
import ToolsDetails from './components/dashboard/toolsDetails';
import UserProfile from './components/profile/userProfile';
import Role from './components/role/role';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import Groups from './components/groups/groups';
import CreateNewRole from './components/role/create-new';
import RolesAndPermissions from './components/role/RoleList';
import { Box } from '@mui/material';
import Dashboard from './components/common/dashboard';
import {
  AccountBalanceWalletOutlined,
  PaymentsOutlined,
  ImportContactsOutlined,
  GroupOutlined,
  TuneOutlined,
  SpaceDashboardOutlined,
  BuildOutlined,
} from '@mui/icons-material';
import { SidebarItem } from './components/dashboard/types';
import GroupList from './components/groups/GroupList';
import CreateUserGroup from './components/groups/CreateGroup';

const sidebarItems: SidebarItem[] = [
  { icon: SpaceDashboardOutlined, label: 'Dashboard', link: '/' },
  { icon: BuildOutlined, label: 'Tools', link: '/settings' },
  { icon: GroupOutlined, label: 'User', link: '/profile' },
  {
    icon: AccountBalanceWalletOutlined,
    label: 'Billing',
    link: '/notifications',
  },
  {
    icon: PaymentsOutlined,
    label: 'Audit logs',
    link: '/notifications',
  },
  {
    icon: ImportContactsOutlined,
    label: 'Finance',
    link: '/notifications',
  },
  {
    icon: TuneOutlined,
    label: 'Project Settings',
    link: '/notifications',
  },
];


const Wrapper = (Component) => {
  return <Box>
      <Dashboard
        sidebarItems={sidebarItems}
        contentJsx={<Component />}
      />
    </Box>
  
}

const theme = createTheme({
  typography: {
    fontFamily: 'Mukta, sans- serif', // Example: using Roboto font
    fontSize: 14, // Example: setting default font size
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    // Other typography options
  },
  // Other theme customizations
});
function App() {
  function setThemeFromJSON(theme) {
    Object.entries(theme).forEach(([key, value]) => {
      if (typeof value === 'string') {
        document.documentElement.style.setProperty(`--${key}`, value);
      }
    });
  }

  useEffect(() => {
    setThemeFromJSON(themeData);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      {' '}
      <BrowserRouter>
        <NotificationProvider>
          <AuthProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/organisation-list" element={<OrganisationList />} />
              <Route
                path="/organisation-create"
                element={<OrganisationCreate />}
              />

              <Route path="/" element={<ManagedToolsDashboard />} />
              <Route path="/tools/toolDetails" element={<ToolsDetails />} />
              <Route path="/user/profile" element={<UserProfile />} />{' '}
              <Route path="/users" element={<Role />} />
              <Route path="/roles/create" element={<CreateNewRole />} />
              <Route path="/roles/edit/:roleId" element={<CreateNewRole />} />
              <Route path="/roles" element={Wrapper(RolesAndPermissions)} />
              <Route path="/groups/create" element={Wrapper(CreateUserGroup)} />
              <Route path="/groups/:groupId" element={Wrapper(Groups)} />
              <Route path="/groups" element={Wrapper(GroupList)} />
            </Routes>
          </AuthProvider>
        </NotificationProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
