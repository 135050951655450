import * as React from 'react';
import { CardContent, Typography, Grid, Card, Box, Stack } from '@mui/material';
import { ToolInstance } from './types';
function ToolDetailInstance({ title, logoUrl, config }: ToolInstance) {
  return (
    <Card sx={{ borderRadius: '20px', border: '1px solid #E5E6EA' }}>
      <CardContent style={{ paddingBottom: '0px', padding: '20px' }}>
        <Grid
          spacing={2}
          style={{
            margin: '0px',
            display: 'flex',
            justifyContent: 'space-between',
            gap: '10px',
          }}>
          <Grid
            xs={12}
            sm={8}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <Typography
              variant="h5"
              component="div"
              sx={{ color: '#1F2937', fontWeight: '600', fontSize: '20px' }}>
              {title}
            </Typography>
            <Stack
              direction="row"
              spacing={0}
              mt={1}
              sx={{ flexWrap: 'wrap', gap: '10px' }}>
              {config.map((configItem, index) => (
                <Typography
                  variant="body2"
                  key={index}
                  sx={{ color: '#6B7281' }}
                  style={{ fontSize: '15px' }}>
                  • {configItem.name}
                </Typography>
              ))}
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            style={{ padding: '0px' }}>
            <Box width={70}>
              
              <img
                src={logoUrl}
                alt="Logo"
                style={{ minWidth: '100%', width: '48px', height: '48px' }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ToolDetailInstance;
