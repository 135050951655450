export const API_ENDPOINTS = {
  AUTH: {
    LOGIN: '/api/users/login',
    LOGOUT: '/logout',
    SIGNUP: '/api/users/signup',
    PRESIGNUP: '/api/users/preSignup',
    VERIFICATION: '/api/users/signup/verification/send',
  },
  USER: {
    PROFILE: '/user/profile',
    FETCH_USERS: '/api/iam/fetch/users',
  },
  MANAGED_TOOLS: {
    GET_ALL: '/api/managedTools/getAll',
  },
  SUPPORTED_TOOLS: {
    GET_ALL: '/api/managedTools/supportedTools/fetch',
    FETCH_ACTIONS: '/api/managedTools/supportedTools/fetch/actions',
  },
  ORGANIZATION: {
    LIST: '/api/users/organisation/list',
    CREATE: '/api/users/organisation/create',
  },
  ROLE: {
    FETCH_USERS_ROLE: '/api/iam/fetch/usersRole',
    CREATE_ROLE: '/api/iam/save/role',
    FETCH_ROLES: '/api/iam/fetch/role',
  },
  GROUPS: {
    FETCH_GROUPS: '/api/iam/fetch/group',
    CREATE_GROUP: '/api/iam/save/group',
  }
};
