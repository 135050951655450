import React, { useCallback, useEffect, useState } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, Box, TextField, TablePagination, InputAdornment, Checkbox } from '@mui/material';
import { Search } from '@mui/icons-material';
import debounce from 'lodash/debounce';
import { useNotification } from '../notification/notification-provider';

interface TableProps<T> {
    headers: { label: string; align?: 'left' | 'center' | 'right' }[];
    renderRow: (item: T) => React.ReactNode;
    fetchData: (params: FetchParams) => Promise<FetchResult<T>>;
    searchPlaceholder?: string;
    rowsPerPageDefault: number;
    showSearch?: boolean;
    showPagination?: boolean;
    showCheckbox?: boolean;
    onSelectionChange?: (selected: string[]) => void;
    preSelectedItems?: string[];
}

interface FetchParams {
    page_size: number;
    search?: string;
    page_last_id?: string | null;
    page_first_id?: string | null;
    next_or_prev?: 'next' | 'prev';
}

interface FetchResult<T> {
    data: any[];
    showNextPage: boolean;
}

function CommonTable<T>({ headers,
    renderRow,
    fetchData,
    searchPlaceholder = 'Search',
    rowsPerPageDefault = 10,
    showSearch = true,
    showPagination = true,
    showCheckbox = false,
    onSelectionChange,
    preSelectedItems = []
}: TableProps<T>) {
    const [data, setData] = useState<any[]>([]);
    const { showNotification } = useNotification();
    const [paginationParam, setPaginationParam] = useState({
        page: 0,
        rowsPerPage: rowsPerPageDefault,
        lastRowId: null as string | null,
        showNextPage: true,
        firstRowId: null as string | null
    });
    const [searchQuery, setSearchQuery] = useState('');

    const debouncedFetchData = useCallback(
        debounce((paginationParam, query) => {
            fetchData({
                page_size: paginationParam.rowsPerPage,
                search: query,
                page_last_id: paginationParam.lastRowId,
                page_first_id: paginationParam.firstRowId,
                next_or_prev: paginationParam.nextOrPrev
            }).then(({ data, showNextPage }) => {
                setPaginationParam({
                    ...paginationParam,
                    showNextPage,
                    lastRowId: data[data.length - 1]?.id,
                    firstRowId: data[0]?.id
                });
                setData(data);
            }).catch(err => showNotification(err.message, 'error'));
        }, 300),
        [fetchData]
    );

    useEffect(() => {
        debouncedFetchData(paginationParam, searchQuery);
        if (onSelectionChange) {
            onSelectionChange(preSelectedItems || []);
        }   
    }, []);


    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        const paginationParamToSet = {
            ...paginationParam,
            page: 0,
            nextOrPrev: 'next' as const,
            lastRowId: null,
            firstRowId: null,
        };
        debouncedFetchData(paginationParamToSet, event.target.value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        const paginationParamToSet = {
            ...paginationParam,
            page: newPage,
            nextOrPrev: paginationParam.page > newPage ? 'prev' : 'next' as const
        };
        debouncedFetchData(paginationParamToSet, searchQuery);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const paginationParamToSet = {
            ...paginationParam,
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10),
            lastRowId: null,
            nextOrPrev: 'next' as const
        };
        debouncedFetchData(paginationParamToSet, searchQuery);
    };

    const count = paginationParam.page * paginationParam.rowsPerPage + data.length;

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (onSelectionChange) {
            onSelectionChange(newSelected);
        }
        setSelected(newSelected);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = Array.from(new Set([...data.map((n) => n.id), ...selected]));
            if (onSelectionChange) {
                onSelectionChange(newSelected);
            }
            setSelected(newSelected);
            return;
        }
        if (onSelectionChange) {
            onSelectionChange([]);
        }
        setSelected([]);
    };


    const [selected, setSelected] = useState<string[]>(preSelectedItems || []);
    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    return (
        <Box style={{ padding: '0' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                {showSearch ? <TextField
                    variant="outlined"
                    placeholder={searchPlaceholder}
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    sx={{ width: '30%' }}
                />
                    : null}
                {
                    showPagination ?
                        <TablePagination
                            component="div"
                            count={count}
                            page={paginationParam.page}
                            onPageChange={handleChangePage}
                            rowsPerPage={paginationParam.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[10, 20, 50]}
                            nextIconButtonProps={{
                                disabled: !paginationParam.showNextPage
                            }}
                        />
                        : null
                }

            </Box>
            <TableContainer component={Paper} elevation={0}>
                <Table sx={{ minWidth: 650 }} aria-label="generic table">
                    <TableHead>
                        <TableRow sx={{ background: '#F3F4F6' }}>
                            {showCheckbox ? <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    indeterminate={selected.length > 0 && selected.length < data.length}
                                    checked={data.length > 0 && selected.length === data.length}
                                    onChange={handleSelectAllClick}
                                    inputProps={{
                                        'aria-label': 'select all users',
                                    }}
                                    sx={{
                                        '&.Mui-checked': {
                                            color: '#4B4EFC',
                                        },
                                        '&.MuiCheckbox-indeterminate': {
                                            color: '#4B4EFC',
                                        },
                                    }}
                                />
                            </TableCell>
                                : null}
                            {headers.map((header, index) => (
                                <TableCell key={index} align={header.align || 'left'}>
                                    {header.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, index) => {
                            const isItemSelected = isSelected(item.id);
                            return <TableRow
                                hover
                                onClick={(event) => handleClick(event, item.id)}
                                role={showCheckbox ? "checkbox" : ''}
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={item.id}
                                selected={isItemSelected}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {showCheckbox ?
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            inputProps={{
                                                'aria-labelledby': `enhanced-table-checkbox-${index}`,
                                            }}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#4B4EFC',
                                                },
                                            }}
                                        />
                                    </TableCell>
                                    : null
                                }
                                {renderRow(item)}
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default CommonTable;