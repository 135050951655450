export const getAuthenticatedUserHeaders = () => {
  const toRet = {
    'user-id': localStorage.getItem('userId'),
    'access-token': localStorage.getItem('accessToken'),
  };

  if (localStorage.getItem('organisationId')) {
    toRet['organisation-id'] = localStorage.getItem('organisationId');
  }

  return toRet;
};
