import React, { createContext } from 'react';
import { Navigate } from 'react-router-dom';
// import Login from './login';
import { AuthContextInterface, LoginData } from './types';
export const AuthContext = createContext<AuthContextInterface | undefined>(
  undefined,
);

export const AuthProvider = ({ children }) => {
  const login = (loginData: LoginData) => {
    localStorage.setItem('userId', loginData?.user.id);
    localStorage.setItem('accessToken', loginData?.token.id);
    localStorage.setItem('userEmail', loginData?.user.email);
  };

  const logout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userEmail');
  };

  const setOrganisation = (organisationId: string) => {
    localStorage.setItem('organisationId', organisationId);
  };

  const removeOrganisation = () => {
    localStorage.removeItem('organisationId');
  };

  return (
    <AuthContext.Provider
      value={{ login, logout, setOrganisation, removeOrganisation }}>
      {children}
    </AuthContext.Provider>
  );
};

export const withAuth = (Component: React.ComponentType<any>) => {
  const WithAuth = (props) => {
    if (
      !(localStorage.getItem('userId') && localStorage.getItem('accessToken'))
    ) {
      return <Navigate to="/login" />;
    }

    return <Component {...props} />;
  };

  return WithAuth;
};

export const withAuthAndOrganisation = (
  Component: React.ComponentType<any>,
) => {
  const WithAuth = (props) => {
    if (
      !(localStorage.getItem('userId') && localStorage.getItem('accessToken'))
    ) {
      return <Navigate to="/login" />;
    }

    if (!localStorage.getItem('organisationId')) {
      return <Navigate to="/organisation-list" />;
    }

    return <Component {...props} />;
  };

  return WithAuth;
};
