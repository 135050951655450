import React from 'react';
import { startCase } from 'lodash';
import { useNavigate } from 'react-router-dom';
import './organisation.css';
import { Stack, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material/styles';
import { OnboardingLayout } from '../onboarding/onboarding';
import { withAuth, AuthContext } from '../login/auth-provider';
import {
  organisationListApi,
  organisationCreateApi,
} from '../../api/organisation-api';
import {
  OnboardingButton,
  OnboardingTextField,
} from '../onboarding/onboarding';
import Logo from '../common/logo';
import { useNotification } from '../notification/notification-provider';
import { OrgList } from './types';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FAFAFA',
    borderBottom: '1px solid #E5E6EA',
    lineHeight: '0.5rem',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '14px',
    backgroundColor: 'white',
    lineHeight: '15px',
    '&:hover': {
      backgroundColor: '#FAFAFA',
    },
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  'box-shadow': '0px 0px 8px 0px #0000001A',
}));
export const OrganisationList = withAuth(function () {
  const [organisationList, setOrganisationList] = React.useState<OrgList[]>([]);
  const { setOrganisation: organisationAuthContext } =
    React.useContext(AuthContext);
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  React.useEffect(() => {
    async function fetchOrganisationListForUser() {
      try {
        const response = await organisationListApi();
        const fetchedOrganisationList = response?.data || [];
        setOrganisationList(fetchedOrganisationList);
        if (fetchedOrganisationList.length === 0) {
          navigate('/organisation-create');
        }
      } catch (err) {
        showNotification('Unable to fetch organisation list', 'error');
      }
    }
    fetchOrganisationListForUser();
  }, [navigate]);

  const handleOrganisationSelection = function (organisationId) {
    organisationAuthContext(organisationId);
    navigate('/');
  };

  return (
    <div className="organisation-add-container">
      <OnboardingLayout
        formHeight="70vh"
        jsx={
          <div className="organisation-actions-container">
            <Logo />
            <StyledTableContainer sx={{ marginTop: '20px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Select Organisation</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {organisationList.map((row) => (
                    <StyledTableRow
                      key={row.id}
                      onClick={(e) => handleOrganisationSelection(row.id)}>
                      <StyledTableCell component="th" scope="row">
                        <Grid container spacing={2}>
                          <Grid item xs={2}>
                            <Avatar
                              sx={{
                                backgroundColor: '#E5E6EA',
                                color: 'black',
                              }}>
                              {row.id[0].toUpperCase()}
                            </Avatar>
                          </Grid>
                          <Grid item xs={8}>
                            <p>{startCase(row.id)}</p>
                          </Grid>
                          <Grid item xs={2}>
                            <ArrowForwardIosIcon
                              sx={{
                                paddingTop: '10px',
                                fontSize: '20px',
                              }}
                            />
                          </Grid>
                        </Grid>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <a
              href="/organisation-create"
              className="add-new-organisation-text">
              + Add New Organisation
            </a>
          </div>
        }
      />
    </div>
  );
});

export const OrganisationCreate = withAuth(function () {
  const userEmail = localStorage.getItem('userEmail');
  const emailFullDomain = userEmail.split('@')[1];
  const emailDomain = emailFullDomain.split('.')[0];
  const [organisationToCreate, setOrganisationToCreate] = React.useState('');
  const [orgError, setOrgError] = React.useState(false);
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  const handleOrganisationCreation = async (event) => {
    event.preventDefault();
    if (!organisationToCreate) {
      setOrgError(true);
      return;
    }
    try {
      await organisationCreateApi(`${emailDomain}-${organisationToCreate}`);
      navigate('/organisation-list');
    } catch (err) {
      showNotification('Unable to create organisation', 'error');
    }
  };

  return (
    <div className="">
      <OnboardingLayout
        formHeight="75vh"
        jsx={
          <div className="organisation-actions-container">
            <form
              onSubmit={handleOrganisationCreation}
              className="create-organisation-form-container">
              <Stack spacing={{ xs: 1, sm: 2, md: 3 }}>
                <Logo />
                <div>
                  <h2 className="header2">Welcome to Quicktools</h2>
                  <p className="sub-text">
                    Please create your organisation name
                  </p>
                </div>
                <p className="sub-text">Enter organisation name</p>
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    <p>{startCase(emailDomain)}</p>
                  </Grid>
                  <Grid item xs={0.5}>
                    <p>-</p>
                  </Grid>
                  <Grid item xs={8}>
                    <OnboardingTextField
                      label="Please enter suffix"
                      type="text"
                      value={organisationToCreate}
                      onChange={(e) => setOrganisationToCreate(e.target.value)}
                      error={orgError}
                      helperText={orgError && 'Organisation name is required'}
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Stack>
              <div className="button-container">
                <OnboardingButton
                  type="submit"
                  variant="contained"
                  // disabled={isLoading}
                >
                  Continue
                </OnboardingButton>
              </div>
            </form>
          </div>
        }
      />
    </div>
  );
});
