import { useNavigate } from 'react-router-dom';
import React from 'react';
import { styled, useTheme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';

import './sidebar.css';
const drawerWidth = 240;
const openedMixin = (theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  zIndex: 2,
  position: 'fixed',
  top: '46px',
});

const closedMixin = (theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  zIndex: 2,
  position: 'fixed',
  top: '46px',
  borderRight: '1.5px solid #D0D4DD',
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  padding: 10,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

function Sidebar({ sidebarItems }) {
  const [activeIcon, setActiveIcon] = useState('Tools');
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleItemClick = (item) => {
    navigate(item.link);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      onMouseEnter={handleDrawerOpen}
      onMouseLeave={handleDrawerClose}>
      <List sx={{ padding: '10px' }}>
        {sidebarItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={handleItemClick}
              sx={{
                minHeight: '50px',
                borderRadius: 2,
                justifyContent: open ? 'initial' : 'center',
                backgroundColor: activeIcon === item.label ? '#F5F5FF' : '',

                px: 2.5,
                // '& .MuiListItemIcon-root': { color: '#6B7281' },
                // Hover effect for background, text, and icon
                '&:hover': {
                  border: '1.1px solid #D0D4DC',
                  backgroundColor: '#F5F5FF',
                  '& .MuiListItemIcon-root': {
                    // Target icon
                    color: '#4B4EFC', // Change icon color (e.g., to blue)
                  },
                  '& .MuiListItemText-primary': {
                    // Target text
                    color: '#4B4EFC', // Change text color (e.g., to blue)
                  },
                },
              }}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: activeIcon === item.label ? '#4B4EFC' : '',
                }}>
                <item.icon size={12} />
              </ListItemIcon>
              <ListItemText
                primary={item.label}
                sx={{ opacity: open ? 1 : 0, color: '#6B7281' }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

export default Sidebar;

type StyledListItemButtonProps = {
  open: boolean;
};

type StyledListItemIconProps = {
  open: boolean;
};

type StyledListItemTextProps = {
  open: boolean;
};

const StyledListItemButton = styled(ListItemButton)<StyledListItemButtonProps>(
  ({ theme, open }) => ({
    minHeight: 48,
    borderRadius: 2,
    justifyContent: open ? 'initial' : 'center',
    '&:hover': {
      border: `1.1px solid gray`,

      '& .MuiListItemIcon-root': {
        color: '#4B4EFC',
      },
      '& .MuiListItemText-primary': {
        color: '#4B4EFC',
      },
    },
  }),
);

const StyledListItemIcon = styled(ListItemIcon)(
  ({ open }: StyledListItemIconProps) => ({
    minWidth: 0,
    justifyContent: 'center',
    marginRight: open ? '10px' : 0,
  }),
);
const StyledListItemText = styled(ListItemText)(
  ({ open }: StyledListItemTextProps) => ({
    opacity: open ? 1 : 0,
  }),
);
