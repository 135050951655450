import axios from 'axios';
import * as config from '../config';
import { API_ENDPOINTS } from './apiEndpoints';
import { getAuthenticatedUserHeaders } from './helper';

export const fetchUsersRole = async (params: { search?: string, page_last_id?: string }) => {
  try {
    const response = await axios({
      method: 'get',
      url: config.coreBackendUrl + API_ENDPOINTS.ROLE.FETCH_USERS_ROLE,
      params,
      headers: {
        ...getAuthenticatedUserHeaders(),
        'Content-Type': 'application/json',
      },
      validateStatus: () => true,
    });

    if (response.status === 200) {
      const data = response.data;
      return {
        data: data?.data,
      };
    } else {
      const errorMessage = response.data?.error?.message || 'Network error!';
      throw new Error(errorMessage);
    }
  } catch (error) {
    throw error;
  }
};


export const fetchUsers = async (params: { search?: string, page_last_id?: string }) => {
  try {
    const response = await axios({
      method: 'get',
      url: config.coreBackendUrl + API_ENDPOINTS.USER.FETCH_USERS,
      params,
      headers: {
        ...getAuthenticatedUserHeaders(),
        'Content-Type': 'application/json',
      },
      validateStatus: () => true,
    });

    if (response.status === 200) {
      const data = response.data;
      return {
        data: data?.users,
        showNextPage: data?.show_next_page,
      };
    } else {
      const errorMessage = response.data?.error?.message || 'Network error!';
      throw new Error(errorMessage);
    }
  } catch (error) {
    throw error;
  }
};
