import React from 'react';
import { Header } from './header';
import Sidebar from './sidebar';
import './dashboard.css';
import { DashboardProps } from './types';
function Dashboard({ contentJsx, sidebarItems }: DashboardProps) {
  return (
    <div className="dashboard-container">
      <div className="navbar">
        <Header />
        <Sidebar sidebarItems={sidebarItems} />
      </div>
      <div className="content">
        <div className="dashboard-content">{contentJsx}</div>
      </div>
    </div>
  );
}

export default Dashboard;
