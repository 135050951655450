import React, { useState } from 'react';
import {
    Typography,
    Button,
    TableCell,
    Box,
    Avatar,
    Tabs,
    Tab,
} from '@mui/material';
import { Add, } from '@mui/icons-material';
import { fetchRoles } from 'src/api/roles';
import { useNavigate } from 'react-router-dom';
import CommonTable from '../common/CommonTable';
import './role.css';
import { generatePrettyName } from 'src/lib/helper';

interface Role {
    id: string;
    name: string;
    description: string;
    num_of_people: number;
    tool: string;
}

const headers = [
    { label: 'Name' },
    { label: 'No. of users', align: 'center' as const },
    { label: 'Tool', align: 'center' as const },
    { label: 'Action', align: 'center' as const },
];

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, padding: '0' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const RolesAndPermissions = () => {
    const [tabValue, setTabValue] = useState(0);

    const navigate = useNavigate();

    const fetchRolesWrapper = async (params) => {
        const { roles, showNextPage } = await fetchRoles({
            fetch_default_roles: tabValue === 0,
            ...params
        });
        return { data: roles, showNextPage };
    };

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const renderRow = (role: Role) => (
        <>
            <TableCell component="th" scope="row">
                <Typography variant="subtitle1" className='role-pretty-name'>{generatePrettyName(role.name)}</Typography>
                <Typography variant="body2" color="text.secondary">{role.description}</Typography>
            </TableCell>
            <TableCell align="center" className='table-cell'>{role.num_of_people}</TableCell>
            <TableCell align="center" className='table-cell'>{role.tool}</TableCell>
            <TableCell align="center">
                <Button variant="outlined" color="primary" size="small" className='view-role-button' onClick={() => {
                    if (!(tabValue === 0)) {
                        navigate(`/roles/edit/${role.id}`);
                    }
                }}>
                    View role
                </Button>
            </TableCell>
        </>
    );

    return (
        <Box className='role-list-wrapper'>
            <Typography variant="body2" color="text.secondary" gutterBottom className='header'>
                User management
            </Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h4" component="h1" className='sub-header'>
                    Roles and permissions
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    sx={{ background: '#4B4EFC', height: '3rem', borderRadius: '8px', padding: '16px 20px' }}
                    onClick={() => {
                        navigate('/roles/create');
                    }}
                >
                    Add custom role
                </Button>
            </Box>

            <Tabs value={tabValue} onChange={handleChangeTab} sx={{ mb: 2 }}>
                <Tab label="System roles" />
                <Tab label="Custom roles" />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
                <CommonTable
                    headers={headers}
                    renderRow={renderRow}
                    fetchData={fetchRolesWrapper}
                    searchPlaceholder="Search roles"
                    rowsPerPageDefault={20}
                />
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
                <CommonTable
                    headers={headers}
                    renderRow={renderRow}
                    fetchData={fetchRolesWrapper}
                    searchPlaceholder="Search roles"
                    rowsPerPageDefault={20}
                />
            </TabPanel>

            <Box position="fixed" right={16} bottom={16}>
                <Avatar sx={{ bgcolor: 'primary.main', width: 56, height: 56 }}>A</Avatar>
            </Box>
        </Box>
    );
};

export default RolesAndPermissions;