import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';
import { styled } from '@mui/system';
import Avatar from '@mui/material/Avatar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import { DropdownWrapperProps } from './types';
const MenuItemProfile = styled(BaseMenuItem)(
  ({ theme }) => `
  list-style: none;
  padding: 15px 10px;
  cursor: default;
  user-select: none;
  color:gray;

  &:last-of-type {
    border-bottom: none;
  }

  &:focus {
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[800]};
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[100] : grey[100]};
  }
  `,
);

const MenuButtonStyled = styled(BaseMenuButton)(
  ({ theme }) => `
  display:flex;
  background:transparent;
  align-items:center;
  gap:10px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.8;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: 0px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  `,
);
const Listbox = styled('ul')(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 10px 0px;
  margin: 5px 0;
  min-width: 200px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === 'dark' ? grey[200] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0px 4px 6px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
  };
  z-index: 1;
  `,
);

const DropdownWrapper = ({ name, avatar, items }: DropdownWrapperProps) => {
  const getInitials = (name) => {
    const names = name.split(' ');
    return names
      .map((n) => n[0])
      .join('')
      .toUpperCase();
  };

  return (
    <Dropdown>
      <MenuButtonStyled>
        {avatar && (
          <Avatar>
            <img src={avatar} alt="Avatar" />
          </Avatar>
        )}
        {!avatar && (
          <Avatar sx={{ width: 33, height: 33, fontSize: '17px' }}>
            {getInitials(name)}
          </Avatar>
        )}

        <div className="profile-name">{name}</div>

        <KeyboardArrowDownIcon />
      </MenuButtonStyled>
      <Menu slots={{ listbox: Listbox }}>
        {items.map((item) => (
          <MenuItemProfile key={item.name} onClick={item.onClick}>
            {item.name}
          </MenuItemProfile>
        ))}
      </Menu>
    </Dropdown>
  );
};
const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

export default DropdownWrapper;
