import { Avatar, Box, Button, Stack, TableCell, TextField, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNotification } from '../notification/notification-provider';
import { fetchRoles } from 'src/api/roles';
import './create-group.css';
import CommonTable from '../common/CommonTable';
import { fetchUsers } from 'src/api/users';
import { generatePrettyName, getInitials, parseDate } from 'src/lib/helper';
import { createGroup } from 'src/api/groups';
import { useNavigate } from 'react-router-dom';
import { StyledCancelButton } from '../common/StyledCancelledButton';

const StyledButton = styled(Button)({
    background: '#4B4EFC',
    color: 'white',
    borderRadius: '8px',
    height: '40px',
    padding: '16px 25px',
    '&:hover': {
      background: '#4B4EFC',
    },
    textTransform: 'unset',
    fontSize: '15px',
    fontWeight: '500',
    letterSpacing: '0.02em',
  });

const userColumns = [
    { label: 'Name', field: 'name', width: 150 },
    { label: 'Email', field: 'email', width: 200 },
    { label: 'Created At', field: 'created_at', width: 200 },
];

const roleColumns = [
    { label: 'Name', field: 'name', width: 150 },
    { label: 'Description', field: 'description', width: 200 },
    { label: 'Managed By', field: 'managed_by', width: 200 },
    { label: 'Created At', field: 'created_at', width: 200 },
];

function CreateNewGroup() {

    const [groupName, setGroupName] = useState('');
    const [groupDescription, setGroupDescription] = useState('');

    const fetchUserData = async (params) => {
        const response = await fetchUsers(params);
        return {
            data: response.data,
            showNextPage: response.showNextPage
        }
    };
    const navigate = useNavigate();

    const fetchRoleData = async (params) => {
        const response = await fetchRoles({ ...params, fetch_all_roles: true });
        return {
            data: response.roles,
            showNextPage: response.showNextPage
        }
    };

    const { showNotification } = useNotification();

    const handleSave = async () => {
        if (!groupName) {
            showNotification('Please enter group name', 'error');
            return;
        }

        if (!groupDescription) {
            showNotification('Please enter group description', 'error');
            return;
        }

        const payload = {
            name: groupName,
            description: groupDescription,
            user_list: selectedUsers,
            role_list: selectedRoles
        };
        try {
            await createGroup(payload);
            navigate('/groups');
        } catch (error: any) {
            showNotification(error.message || 'Error creating group', 'error');
        }
    };
    const [selectedUsers, setSelectedUsers] = useState([]);
    const handleUserSelection = (selectedRows: string[]) => {
        setSelectedUsers(selectedRows);
    };

    const [selectedRoles, setSelectedRoles] = useState([]);
    const handleRoleSelection = (selectedRows: string[]) => {
        setSelectedRoles(selectedRows);
    };

    return (
        <Box className='box-container'>
            <Box className='header'>
                <Box>
                    <Typography className='user-management-header'>
                        User Management/Roles and permissions
                    </Typography>
                    <Typography className='create-new-role-header'>
                        Create Group
                    </Typography>
                </Box>
            </Box>
            <Box className='name-description-wrapper'>
                <TextField
                    variant="standard"
                    label="Group Name"
                    className='role-name-input'
                    required
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                />
                <TextField
                    variant="standard"
                    label="Group description"
                    className='role-description-input'
                    required
                    value={groupDescription}
                    onChange={(e) => setGroupDescription(e.target.value)}
                />
            </Box>
            <Box className='projects-tools-select-wrapper'
                sx={{
                    borderBottom: '1px solid #E5E7EB',
                }}>
                <Box className='projects-select-wrapper'>
                    <Typography className='heading'>
                        Add users to the group
                    </Typography>
                    <Typography className='sub-heading'>
                        {selectedUsers.length ? `Selected Users #(${selectedUsers.length})` : 'Select Users'}
                    </Typography>
                    <CommonTable
                        headers={userColumns}
                        onSelectionChange={handleUserSelection}
                        showCheckbox
                        fetchData={fetchUserData}
                        renderRow={(row: any) => {
                            return <>
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <Typography sx={{ color: '#1F2937', fontWeight: '600' }}>
                                            <Avatar
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    fontSize: '17px',
                                                }}>
                                                {getInitials(row.firstname || row.email, row.lastname || '')}
                                            </Avatar>
                                        </Typography>
                                        <Stack>
                                            <Typography
                                                sx={{ color: '#1F2937', fontWeight: '600' }}>
                                                {' '}
                                                {(row.firstname || '') + ' ' + (row.lastname || '')}
                                            </Typography>
                                            <Typography
                                                sx={{ color: '#6B7281', fontWeight: '500' }}>
                                                {row.email}
                                            </Typography>
                                        </Stack>

                                    </Box>
                                </TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{parseDate(row.created_at)}</TableCell>
                            </>
                        }}
                        rowsPerPageDefault={20}
                    />
                </Box>
            </Box>
            <Box className='projects-tools-select-wrapper'
                sx={{
                    borderBottom: '1px solid #E5E7EB',
                    marginTop: '20px'
                }}>
                <Box className='projects-select-wrapper'>
                    <Typography className='heading'>
                        Add roles to the group
                    </Typography>
                    <Typography className='sub-heading'>
                        {selectedRoles.length ? `Selected Roles #(${selectedRoles.length})` : 'Select Roles'}
                    </Typography>
                    <CommonTable
                        headers={roleColumns}
                        onSelectionChange={handleRoleSelection}
                        showCheckbox
                        fetchData={fetchRoleData}
                        renderRow={(row: any) => {
                            return <>
                                <TableCell>
                                    {row.name}
                                </TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell>{row.organisation_id ? generatePrettyName(row.organisation_id) : 'System'}</TableCell>
                                <TableCell>{parseDate(row.created_at)}</TableCell>
                            </>
                        }}
                        rowsPerPageDefault={20}
                    />
                </Box>
            </Box>
            <Box className='button-wrapper'>
                <StyledButton onClick={handleSave}>
                    Create Group
                </StyledButton>
                <StyledCancelButton onClick={() => navigate('/groups')}>
                    Cancel
                </StyledCancelButton>
            </Box>
        </Box>
    );
}

export default CreateNewGroup;
