import { Box, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Dashboard from '../common/dashboard';
import {
  AccountBalanceWalletOutlined,
  PaymentsOutlined,
  ImportContactsOutlined,
  GroupOutlined,
  TuneOutlined,
  SpaceDashboardOutlined,
  BuildOutlined,
} from '@mui/icons-material';
import { Button, Checkbox } from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fetchSupportedToolsActionsApi, fetchSupportedToolsApi } from 'src/api/supported-tools-api';
import { useNotification } from '../notification/notification-provider';
import { createRole, fetchRole } from 'src/api/roles';
import { managedToolsApi } from 'src/api/managed-tools-api';
import './role.css';

const SaveButton = styled(Button)({
  background: '#4B4EFC',
  color: 'white',
  border: '8px',
  borderRadius: '8px',
  height: '3rem',
  padding: '16px 25px',
  '&:hover': {
    background: '#4B4EFC',
  },
  'text-transform': 'unset',
  'font-size': '15px',
  'font-weight': '500',
  'letter-spacing': '0.02em',
  marginTop: '20px'
});

interface SupportedTool {
  name: string;
  category: string;
  id: string;
}

function CreateNewRole() {
  const navigate = useNavigate();
  const sidebarItems = [
    { icon: SpaceDashboardOutlined, label: 'Dashboard', link: '/' },
    { icon: BuildOutlined, label: 'Tools', link: '/settings' },
    { icon: GroupOutlined, label: 'User', link: '/profile' },
    {
      icon: AccountBalanceWalletOutlined,
      label: 'Billing',
      link: '/notifications',
    },
    {
      icon: PaymentsOutlined,
      label: 'Audit logs',
      link: '/notifications',
    },
    {
      icon: ImportContactsOutlined,
      label: 'Finance',
      link: '/notifications',
    },
    {
      icon: TuneOutlined,
      label: 'Project Settings',
      link: '/notifications',
    },
  ];

  // get role id from url
  const { roleId } = useParams();

  const [value, setValue] = useState<SupportedTool | null>(null);
  const [checkAll, setCheckAll] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [checkAllSub, setCheckAllSub] = useState([]);
  const [permission, setPermissions] = useState([]);
  const [supportedTools, setSupportedTools] = useState<SupportedTool[]>([]);
  const [roleName, setRoleName] = useState('');
  const [roleDescription, setRoleDescription] = useState('');
  const [resources, setResources] = useState([]);
  const [projects, setProjects] = useState([]);

  const handleCheckAll = (listName, isChecked, relatedActions, override = true) => {
    setCheckAllSub((prev) => {
      if (checkAll && !isChecked) setCheckAll('');
      if (isChecked){
        setSelectedPermissions((prev) => {
          return [...prev, ...relatedActions];
        });
         return [...prev, listName];
      }
      else {
        if (override) {
          setSelectedPermissions((prev) => {
            return prev.filter((curName) => !relatedActions.includes(curName));
          });
        }
         return prev.filter((curName) => curName !== listName);
      }
    });
  };

  const handleCheckboxChange = (listName, subPermissionName, isChecked, relatedActions) => {
    if (checkAll && !isChecked) setCheckAll('');
    if (checkAllSub.includes(listName) && !isChecked)
      setCheckAllSub((prev) => {
        return prev.filter((curName) => curName !== listName);
      });

    setSelectedPermissions((prevPermissions) => {
      if (isChecked) {
        const newPermissions = [...prevPermissions, subPermissionName];
        if (relatedActions.filter((permission) => newPermissions.includes(permission)).length === relatedActions.length) {
          handleCheckAll(listName, true, relatedActions, false);
        }
        return newPermissions;
      } else {
        return prevPermissions.filter(
          (permission) => permission !== subPermissionName,
        );
      }
    });
  };

  useEffect(() => {
    (async () => {
      const apiCalls =[
        fetchSupportedToolsApi(),
        managedToolsApi('Active'),
      ];
      if (roleId) {
        apiCalls.push(fetchRole(roleId));
      }
      const [supportedTools, projects, roleData] = await Promise.all(apiCalls);
      const allProjects = [...(projects?.data), { name: 'All', id: '*' }];
      setSupportedTools(supportedTools);
      setProjects(allProjects);
      if (roleData) {
        setRoleName(roleData.name);
        setRoleDescription(roleData.description);
        setSelectedPermissions(roleData.actions);
        const resources = allProjects.filter((resource) => roleData.resource.includes(resource.id));
        setResources(resources);
        const tool = supportedTools.find((tool) => tool.name === roleData.tool);
        setValue(tool);
        if (tool) {
          fetchSupportedToolsActionsApi(tool.id).then((data) => {
            setPermissions(data);
          });
        }

      }
    })();
  }, [roleId]);

  const { showNotification } = useNotification();

  const handleSave = async () => {
    if (!roleName) {
      showNotification('Please enter tool name', 'error');
      return;
    }
    if (!roleDescription) {
      showNotification('Please enter tool description', 'error');
      return;
    }

    if (!resources.length) {
      showNotification('Please select at least one project', 'error');
      return;
    }

    if (!value) {
      showNotification('Please select at least one tool', 'error');
      return;
    }

    const payload = {
      name: roleName,
      description: roleDescription,
      actions: [...selectedPermissions, ...checkAllSub],
      resource: resources.map((resource) => resource.id || resource.name),
      tool: value.name
    };

    if (checkAll) {
      payload.actions = ['*'];
    }
    await createRole(payload);
    navigate('/roles');
  };

  return (
    <Box>
      <Dashboard
        sidebarItems={sidebarItems}
        contentJsx={
          <Box className='box-container'>
            <Box className='header'>
              <Box>
                <Typography className='user-management-header'>
                  User Management/Roles and permissions
                </Typography>
                <Typography className='create-new-role-header'>
                  Create custom role
                </Typography>
              </Box>
            </Box>
            <Box className='name-description-wrapper'>
              <TextField
                variant="standard"
                label="Name of the Role"
                className='role-name-input'
                required
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
              />
              <TextField
                variant="standard"
                label="Role Description"
                className='role-description-input'
                required
                value={roleDescription}
                onChange={(e) => setRoleDescription(e.target.value)}
              />
            </Box>
            <Box className='projects-tools-select-wrapper'>
              <Box className='projects-select-wrapper'>
                <Typography className='heading'>
                  Select Projects
                </Typography>
                <Typography className='sub-heading'>
                  Select one or more projects from the dropdown below to set permission for
                </Typography>
                <Autocomplete
                  value={resources}
                  onChange={(event: any, newValue) => {
                    setResources(newValue);
                  }}
                  multiple
                  getOptionLabel={(option) => option.name}
                  options={projects}
                  renderInput={(params) => (
                    <TextField {...params} label="Projects" variant="standard" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      {option.name}
                    </li>
                  )}
                  disableClearable
                  className='autocomplete'
                />
              </Box>
              <Box className='tools-select-wrapper'>
              <Typography
                className='heading'>
                Select Tools
              </Typography>
              <Typography
                className='sub-heading'>
                Select one or more tools from the dropdown below to set permission
                for{' '}
              </Typography>
              <Autocomplete
                value={value?.name}
                onChange={(event: any, newValue: SupportedTool) => {
                  setValue(newValue);
                  if (newValue) {
                    fetchSupportedToolsActionsApi(newValue.id).then((data) => {
                      setPermissions(data);
                    });
                  }
                }}
                getOptionLabel={(option) => option.name}
                options={supportedTools}
                renderInput={(params) => (
                  <TextField {...params} label="Tools" variant="standard" />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option.name}
                  </li>
                )}
                disableClearable
                className='autocomplete'
              />
              </Box>
            </Box>
            {value && (
              <Box className='permissions-wrapper'>
                <Typography className='tool-name'>
                  {value?.name}
                </Typography>
                <Box className='allow-all-access-wrapper'>
                  <Checkbox
                    color="default"
                    checked={checkAll === '*'}
                    onChange={() => {
                      if (checkAll) { 
                        setCheckAll('');
                      } else {
                        setCheckAll('*');
                      }
                    }}
                  />
                  <Typography className='allow-all-access-text'>
                    Allow all access for {value.name}
                  </Typography>
                </Box>
                {permission.map((list, idx) => (
                  <Accordion className='accordion'>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header">
                      <Typography className='heading'>
                        {list.name} ({list?.related_actions?.length})
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className='details'>
                      <Box className='actions-wrapper'>
                        <Checkbox
                          color="default"
                          className='checkbox'
                          checked={checkAll ? true : false || checkAllSub.includes(list.permission_id)}
                          onChange={(e) => {
                            handleCheckAll(list.permission_id, e.target.checked, list.related_actions?.map((item) => item.permission_id));
                          }}
                        />
                        <Typography className='heading'>
                          All {list.name} actions 
                        </Typography>
                      </Box>
                      <Box className='sub-actions-wrapper'>
                        {list?.related_actions?.map((item, idx) => (
                          <Box key={idx} className='sub-action'>
                            <Checkbox
                              className='checkbox'
                              color="default"
                              checked={
                                selectedPermissions.includes(item.permission_id) ||
                                checkAll ? true : false ||
                                checkAllSub.includes(list.permission_id)
                              }
                              onChange={(e) => {
                                handleCheckboxChange(
                                  list.permission_id,
                                  item.permission_id,
                                  e.target.checked,
                                  list.related_actions?.map((item) => item.permission_id)
                                );
                              }}
                            />
                            <Typography className='sub-action-text'>
                              {item.name}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
                <SaveButton onClick={handleSave} >
                  Save
                </SaveButton>
              </Box>
            )}
          </Box>
        }
      />
    </Box>
  );
}

export default CreateNewRole;
