import React from 'react';
import './onboarding.css';
import { Button, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { OnboardingLayoutProps } from './types';
import OnboardingImage from '../../assets/images/login-signup.png';
export const OnboardingLayout: React.FC<OnboardingLayoutProps> = ({
  jsx,
  formHeight,
}) => (
  <div>
    <div className="container">
      <div className="left-container">
        <img className="image-container" src={OnboardingImage} alt='Onboarding' />
        <div className="onboarding-image-text-container">
          <h1>Maximizing the developer’s productivity </h1>
          <h3>
            Streamline deployment. Cloud or on-premise. Scalable and secure.
          </h3>
        </div>
      </div>
      <div className="right-container">
        <div className="right-form-container" style={{ height: formHeight }}>
          {jsx}
        </div>
      </div>
    </div>
  </div>
);
export const OnboardingButton = styled(Button)({
  background: '#4B4EFC',
  border: '8px',
  padding: '0px 25px',
  height: '3rem',
  width: '100%',
  color: 'white',
  '&:hover': {
    background: '#4B4EFC',
  },
  'text-transform': 'unset',
  'font-size': '15px',
  'font-weight': '500',
  'letter-spacing': '0.02em',
});

export const OnboardingTextField = styled(TextField)({
  '&& .MuiInput-underline:hover:before': {
    borderBottom: '1px solid black',
  },
  '& label.Mui-focused': {
    color: '#4B4EFC',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#4B4EFC',
  },
  marginTop: '-8px',
  width: '100%',
});
