import React, { useCallback, useEffect, useState } from 'react';
import {
    Typography,
    Button,
    TableCell,
    Box,
    Avatar,
} from '@mui/material';
import { Add, } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import CommonTable from '../common/CommonTable';
import './grouplist.css';
import { fetchGroups } from 'src/api/groups';
import { generatePrettyName } from 'src/lib/helper';

interface GroupType {
    id: string;
    name: string;
    description: string;
    num_of_people: number;
    num_of_roles: string;
}

const headers = [
    { label: 'Name' },
    { label: 'No. of users', align: 'center' as const },
    { label: 'No. of roles', align: 'center' as const },
    { label: 'Action', align: 'center' as const },
];

const GroupList = () => {

    const navigate = useNavigate();

    const fetchRolesWrapper = async (params) => {
        const { groups, showNextPage } = await fetchGroups({
            ...params
        });
        return { data: groups, showNextPage };
    };

    const renderRow = (group: GroupType) => (
        <>
            <TableCell component="th" scope="row">
                <Typography variant="subtitle1" className='role-pretty-name'>{generatePrettyName(group.name)}</Typography>
                <Typography variant="body2" color="text.secondary">{group.description}</Typography>
            </TableCell>
            <TableCell align="center" className='table-cell'>{group.num_of_people}</TableCell>
            <TableCell align="center" className='table-cell'>{group.num_of_roles}</TableCell>
            <TableCell align="center">
                <Button variant="outlined" color="primary" size="small" className='view-role-button' onClick={() => {
                    navigate(`/groups/${group.id}`);
                }}>
                    View Group
                </Button>
            </TableCell>
        </>
    );

    return (
        <Box className='role-list-wrapper'>
            <Typography variant="body2" color="text.secondary" gutterBottom className='header'>
                User Management
            </Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} >
                <Typography variant="h4" component="h1" className='sub-header'>
                    Groups
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    sx={{ background: '#4B4EFC', height: '3rem', borderRadius: '8px', padding: '16px 20px' }}
                    onClick={() => {
                        navigate('/groups/create');
                    }}
                >
                    Add Group
                </Button>
            </Box>

            <CommonTable
                headers={headers}
                renderRow={renderRow}
                fetchData={fetchRolesWrapper}
                searchPlaceholder="Search Groups"
                rowsPerPageDefault={20}
            />

            <Box position="fixed" right={16} bottom={16}>
                <Avatar sx={{ bgcolor: 'primary.main', width: 56, height: 56 }}>A</Avatar>
            </Box>
        </Box>
    );
};

export default GroupList;