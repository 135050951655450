import axios from 'axios';
import * as config from '../config';
import { API_ENDPOINTS } from './apiEndpoints';
import { getAuthenticatedUserHeaders } from './helper';

export const fetchGroups = async (params: {search?: string, page_last_id?: string, fetch_default_roles?: boolean, page_size?: number, page_first_id?: string, next_or_prev?: string}) => {
    try {
      const { search, page_last_id, fetch_default_roles, page_size, page_first_id, next_or_prev } = params;
      const response = await axios({
        method: 'get',
        url: config.coreBackendUrl + API_ENDPOINTS.GROUPS.FETCH_GROUPS,
        params: {
            search,
            page_last_id,
            fetch_default_roles,
            page_size,
            page_first_id,
            next_or_prev
        },
        headers: {
          ...getAuthenticatedUserHeaders(),
          'Content-Type': 'application/json',
        },
        validateStatus: () => true,
      });
  
      if (response.status === 200) {
        const data = response.data;
        return { 
            groups: data?.groups, 
            showNextPage: data?.show_next_page 
        };
      } else {
        const errorMessage = response.data?.error?.message || 'Network error!';
        throw new Error(errorMessage);
      }
    } catch (error) {
      throw error;
    }
  };

export const fetchGroupDetails = async (groupId: string) => {
    try {

        const response = await axios({
            method: 'get',
            url: config.coreBackendUrl + API_ENDPOINTS.GROUPS.FETCH_GROUPS + '/' + groupId,
            headers: {
                ...getAuthenticatedUserHeaders(),
                'Content-Type': 'application/json',
            },
            validateStatus: () => true,
        });

        if (response.status === 200) {
        const data = response.data;
        return data;
        } else {
        const errorMessage = response.data?.error?.message || 'Network error!';
        throw new Error(errorMessage);
        }
    } catch (error) {
        throw error;
    }
};

export const createGroup = async (groupData: any) => {
    try {
        const response = await axios({
            method: 'post',
            url: config.coreBackendUrl + API_ENDPOINTS.GROUPS.CREATE_GROUP,
            data: groupData,
            headers: {
                ...getAuthenticatedUserHeaders(),
                'Content-Type': 'application/json',
            },
            validateStatus: () => true,
        });

        if (response.status === 200) {
            const data = response.data;
            return data;
        } else {
            const errorMessage = response.data?.error?.message || 'Network error!';
            throw new Error(errorMessage);
        }
    } catch (error) {
        throw error;
    }
};