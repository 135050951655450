import { Box, CircularProgress, InputAdornment, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Dashboard from '../common/dashboard';
import {
  AccountBalanceWalletOutlined,
  PaymentsOutlined,
  ImportContactsOutlined,
  GroupOutlined,
  TuneOutlined,
  SpaceDashboardOutlined,
  BuildOutlined,
  SearchOutlined,
} from '@mui/icons-material';
import { StyledTabsWrapper } from '../dashboard/managed-tools-dashboard';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Avatar from '@mui/material/Avatar';
import './role.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Stack } from '@mui/material';
import { Button } from '@mui/material';
import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { fetchUsersRole } from 'src/api/users';
import { getInitials } from 'src/lib/helper';
import { debounce } from 'lodash';
import { fetchRoles } from 'src/api/roles';

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  minWidth: '100vw',
}));

const StyledButton = styled(Button)({
  background: '#4B4EFC',
  color: 'white',
  border: '8px',
  borderRadius: '8px',
  height: '3rem',
  width: '100%',
  padding: '16px 25px',
  '&:hover': {
    background: '#4B4EFC',
  },
  'text-transform': 'unset',
  'font-size': '15px',
  'font-weight': '500',
  'letter-spacing': '0.02em',
});

const OutlinedButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  '&:hover': {
    borderColor: theme.palette.primary.dark,
  },
  textTransform: 'none',
  fontSize: '15px',
  fontWeight: 500,
  padding: '10px 20px',
  borderRadius: '8px',
}));

function Role() {
  const [open, setOpen] = React.useState(false);
  const [tab, setTab] = useState('Active');
  const [selectedRole, setSelectedRole] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const sidebarItems = [
    { icon: SpaceDashboardOutlined, label: 'Dashboard', link: '/' },
    { icon: BuildOutlined, label: 'Tools', link: '/settings' },
    { icon: GroupOutlined, label: 'User', link: '/profile' },
    {
      icon: AccountBalanceWalletOutlined,
      label: 'Billing',
      link: '/notifications',
    },
    {
      icon: PaymentsOutlined,
      label: 'Audit logs',
      link: '/notifications',
    },
    {
      icon: ImportContactsOutlined,
      label: 'Finance',
      link: '/notifications',
    },
    {
      icon: TuneOutlined,
      label: 'Project Settings',
      link: '/notifications',
    },
  ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };
  const options = ['Admin', 'User', 'Super Admin'];
  const projectOptions = ['India', 'Hungaryr', 'USA'];

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rows, setRows] = useState([]);

  const handlePopupOpen = () => {
    setOpen(true);
  };

  const handlePopupClose = () => {
    setOpen(false);
    setSelectedRole(null);
    setUsers([]);
  };

  const searchRoles = debounce(async (searchTerm) => {
    setLoading(true);
    try {
      // Replace this with your actual API call
      const response = await fetchRoles({ search: searchTerm });
      const { roles, showNextPage } = response;
      setRoles(roles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setLoading(false);
    }
  }, 300);

  const isValidEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    fetchUsersRole({}).then(({ data }) => {
      setRows(data);
    });
  }, []);

  const [invitees, setInvitees] = useState([{ email: '', role: null, project: null }]);

  // ... existing code ...

  const handleAddMore = () => {
    setInvitees([...invitees, { email: '', role: null, project: null }]);
  };

  return (
    <Box>
      <Dashboard
        sidebarItems={sidebarItems}
        contentJsx={
          <Box sx={{ paddingLeft: '20px', paddingTop: '10px' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Box>
                <Typography sx={{ color: '#6B7281', fontWeight: '500' }}>
                  User Management
                </Typography>
                <Typography
                  sx={{
                    color: '#1F2937',
                    fontWeight: '600',
                    fontSize: '50px',
                  }}>
                  Users
                </Typography>
              </Box>
              <Box>
                <StyledButton onClick={handlePopupOpen}>
                  + Invite New
                </StyledButton>
                <BootstrapDialog
                  onClose={handlePopupClose}
                  fullWidth={true}
                  maxWidth={'sm'}
                  aria-labelledby="customized-dialog-title"
                  open={open}>
                  <DialogTitle
                    sx={{ m: 0, p: 2, fontWeight: '600' }}
                    id="customized-dialog-title">
                    Invite Users
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={handlePopupClose}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                  </IconButton>
                  <DialogContent dividers>
                    <Typography variant="h4" gutterBottom>Invite users</Typography>
                    <Box sx={{ mt: 2, mb: 4 }}>
                      <Typography variant="body2" color="text.secondary">User management</Typography>
                    </Box>
                    {invitees.map((invitee, index) => (
                      <Box key={index} sx={{ mb: 3 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                          <TextField
                            fullWidth
                            label="Email"
                            value={invitee.email}
                            onChange={(e) => {
                              const newInvitees = [...invitees];
                              newInvitees[index].email = e.target.value;
                              setInvitees(newInvitees);
                            }}
                            InputProps={{
                              startAdornment: invitee.email && (
                                <Avatar sx={{ width: 24, height: 24, mr: 1 }}>
                                  {invitee.email[0].toUpperCase()}
                                </Avatar>
                              ),
                            }}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                          <Autocomplete
                            fullWidth
                            options={[]} // Replace with your role options
                            renderInput={(params) => <TextField {...params} label="Add role" />}
                            value={invitee.role}
                            onChange={(event, newValue) => {
                              const newInvitees = [...invitees];
                              newInvitees[index].role = newValue;
                              setInvitees(newInvitees);
                            }}
                          />
                          <Autocomplete
                            fullWidth
                            options={[]} // Replace with your project options
                            renderInput={(params) => <TextField {...params} label="Add project" />}
                            value={invitee.project}
                            onChange={(event, newValue) => {
                              const newInvitees = [...invitees];
                              newInvitees[index].project = newValue;
                              setInvitees(newInvitees);
                            }}
                          />
                        </Box>
                      </Box>
                    ))}
                    <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
                      <StyledButton
                        variant="contained"
                        onClick={() => {
                          // Implement send invite logic
                          console.log('Sending invites:', invitees);
                        }}
                      >
                        Send invite
                      </StyledButton>
                      <OutlinedButton variant="outlined" onClick={handleAddMore}>
                        Add more
                      </OutlinedButton>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handlePopupClose}
                      sx={{
                        border: '1px solid #D0D4DC',
                        padding: '8px 20px',
                        color: '#6B7281',
                      }}>
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        // Add logic to send invites
                        console.log('Inviting users:', users);
                        console.log('Selected role:', selectedRole);
                        handlePopupClose();
                      }}
                      sx={{
                        background: '#4B4EFC',
                        padding: '8px 20px',
                        color: 'white',
                        '&:hover': {
                          background: '#F5F5FF',
                          color: '#4B4EFC',
                        },
                      }}>
                      Send Invites
                    </Button>
                  </DialogActions>
                </BootstrapDialog>
              </Box>
            </Box>
            <TabContext value={tab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleTabChange}
                  aria-label="Tab"
                  className="tabs-list"
                  TabIndicatorProps={{
                    style: {
                      background: '#4B4EFC',
                      color: 'black',
                    },
                  }}>
                  <StyledTabsWrapper value="Active" label="Active" />
                  <StyledTabsWrapper value="Inactive" label="Inactive" />
                </TabList>
              </Box>
              <TabPanel value="Active" sx={{ padding: '20px 0px !important' }}>
                {' '}
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={{ background: '#FAFAFA' }}>
                      <TableRow>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Role</TableCell>
                        <TableCell align="left">Project</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.Name}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}>
                          <TableCell
                            align="left"
                            sx={{ color: '#6B7281', fontWeight: '600' }}>
                            {' '}
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}>
                              {row.AvatarUrl && (
                                <Avatar>
                                  <img
                                    src={row.AvatarUrl}
                                    className="avatar"
                                    alt="Avatar"
                                  />
                                </Avatar>
                              )}
                              {!row.AvatarUrl && (
                                <Avatar
                                  sx={{
                                    width: 40,
                                    height: 40,
                                    fontSize: '17px',
                                  }}>
                                  {getInitials(row.firstname || row.email, row.lastname || '')}
                                </Avatar>
                              )}
                              <Stack>
                                <Typography
                                  sx={{ color: '#1F2937', fontWeight: '600' }}>
                                  {' '}
                                  {(row.firstname || '') + ' ' + (row.lastname || '')}
                                </Typography>
                                <Typography
                                  sx={{ color: '#6B7281', fontWeight: '500' }}>
                                  {row.email}
                                </Typography>
                              </Stack>
                            </Box>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              sx={{ color: '#1F2937', fontWeight: '600' }}>
                              {row.group_names}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ color: '#6B7281', fontWeight: '600' }}>
                            <Typography
                              sx={{ color: '#1F2937', fontWeight: '600' }}>
                              {row.Project}
                            </Typography>{' '}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            </TabContext>
          </Box>
        }
      />
    </Box>
  );
}

export default Role;
