import Dashboard from '../common/dashboard';
import {
  AccountBalanceWalletOutlined,
  PaymentsOutlined,
  ImportContactsOutlined,
  GroupOutlined,
  TuneOutlined,
  SpaceDashboardOutlined,
  BuildOutlined,
} from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import CountrySelect from '../common/CountrySelect';
import { Box, Grid, Stack } from '@mui/material';
import React from 'react';
import { OnboardingButton } from '../onboarding/onboarding';
import { Button, TextField, SxProps } from '@mui/material';
import { styled } from '@mui/system';
import './userProfile.css';
function UserProfile() {
  const sidebarItems = [
    { icon: SpaceDashboardOutlined, label: 'Dashboard', link: '/' },
    { icon: BuildOutlined, label: 'Tools', link: '/settings' },
    { icon: GroupOutlined, label: 'User', link: '/profile' },
    {
      icon: AccountBalanceWalletOutlined,
      label: 'Billing',
      link: '/notifications',
    },
    {
      icon: PaymentsOutlined,
      label: 'Audit logs',
      link: '/notifications',
    },
    {
      icon: ImportContactsOutlined,
      label: 'Finance',
      link: '/notifications',
    },
    {
      icon: TuneOutlined,
      label: 'Project Settings',
      link: '/notifications',
    },
  ];
  return (
    <div>
      {' '}
      <Dashboard
        sidebarItems={sidebarItems}
        contentJsx={
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingRight: '20px',
                marginLeft: '10px',
              }}>
              <h1 className="profile-heading-text">User Profile</h1>
              <div>
                <OnboardingButton>Save Details</OnboardingButton>
              </div>
            </Box>
            <Grid
              container
              spacing={4}
              columns={14}
              sx={{ marginTop: '7px', marginLeft: '-20px' }}>
              <Grid item xs={12} md={6}>
                <ProfileTextField label="Full Name" variant="standard" />
              </Grid>
              <Grid item xs={12} md={6}>
                <ProfileTextField label="Email address" variant="standard" />
              </Grid>
              <Grid item xs={12} md={6} sx={{ marginTop: '-8px' }}>
                <CountrySelect />
              </Grid>
              <Grid item xs={12} md={6}>
                <ProfileTextField
                  label="Phone"
                  variant="standard"
                  type="number"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <ProfileTextField label="State" variant="standard" />
              </Grid>
              <Grid item xs={12} md={6}>
                <ProfileTextField label="City" variant="standard" />
              </Grid>
            </Grid>{' '}
            <div className="profile-newsletter-container">
              <Checkbox defaultChecked color="default" />
              <div>
                {' '}
                Newsletter (Receive latest news about Quicktools features,
                supported softwares, tips.)
              </div>
            </div>
            <button className="delete-user-button">Delete User</button>
          </>
        }
      />
    </div>
  );
}

export const ProfileTextField = styled(TextField)({
  '&& .MuiInput-underline:hover:before': {
    borderBottom: '1px solid black',
  },
  '& label.Mui-focused': {
    color: '#4B4EFC',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#4B4EFC',
  },
  marginTop: '-8px',
  width: '100%',
});

export default UserProfile;
