import React from 'react';
import { Button, ButtonProps, styled } from "@mui/material";

interface StyledCancelButtonProps extends ButtonProps {
  height?: string;
  isActive?: boolean;
  marginRight?: string;
  width?: string;
}

const StyledButton = styled(Button)<{ $height?: string; $isActive?: boolean; $marginRight?: string; $width?: string }>(({ $height, $isActive, $marginRight, $width }) => ({
    background: $isActive ? '#808080' : '#D3D3D3',
    color: $isActive ? 'white' : '#4A4A4A',
  borderRadius: '8px',
  height: $height || '40px',
  padding: '16px 25px',
  '&:hover': {
    background: $isActive ? '#696969' : '#C0C0C0',
  },
  '&:disabled': {
    background: '#F5F5F5',
    color: '#BDBDBD',
  },
  marginLeft: '10px',
  textTransform: 'unset',
  fontSize: '15px',
  fontWeight: '500',
  letterSpacing: '0.02em',
  transition: 'background-color 0.3s, color 0.3s',
  marginRight: $marginRight || '0px',
  width: $width || '',
}));

export const StyledCancelButton: React.FC<StyledCancelButtonProps> = ({ height, isActive, marginRight, width, ...props }) => {
  return <StyledButton $height={height} $isActive={isActive} $marginRight={marginRight} $width={width} {...props} />;
};