import Dashboard from '../common/dashboard';
import TuneIcon from '@mui/icons-material/Tune';
import BuildIcon from '@mui/icons-material/Build';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import GroupIcon from '@mui/icons-material/Group';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PaymentsIcon from '@mui/icons-material/Payments';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Stack } from '@mui/material';
import React from 'react';
import './toolsDetails.css';
import ToolDetailInstance from '../common/toolDetailInstance';
function ToolsDetails() {
  const sidebarItems = [
    { icon: SpaceDashboardIcon, label: 'Dashboard', link: '/' },
    { icon: BuildIcon, label: 'Tools', link: '/settings' },
    { icon: GroupIcon, label: 'User', link: '/profile' },
    {
      icon: AccountBalanceWalletIcon,
      label: 'Billing',
      link: '/notifications',
    },
    {
      icon: PaymentsIcon,
      label: 'Audit logs',
      link: '/notifications',
    },
    {
      icon: ImportContactsIcon,
      label: 'Finance',
      link: '/notifications',
    },
    {
      icon: TuneIcon,
      label: 'Project Settings',
      link: '/notifications',
    },
  ];
  const instanceData = [
    {
      title: 'Kafka 1st Instance',
      logoUrl:
        'https://s3-alpha-sig.figma.com/img/a93a/7fd9/0b2b2d0e4fbb437d47ff9f5363b31d15?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TYeexspjX834yWPU5PtZoNb5x4NmEuVKwOxSCDUrloeWxd5JXnTE118a8dBj821ZIMJBx1bbbxby2oKPnvjDYSWh92~2do4KfkJ31VsXTz8s7XcXIIdXPSD8rt5v2~puxaL40nwhIepdANRKdf3T~ozZnRY9wHV5kRnAG2PgRg-EawcKECEL9~XNAINNjX5iwG7~mLumiTmrSj~DbMlb6ZWv5no62S5WpBHhKJEh~boLuVY5wcdQosSC4tPMdtfHmLza8B3n8O3VV9roLSGuoKri0KslJJN3Qhr6ynQcM9l8yL-ATM-pSNT1H3q36ix7d0njltMUFVjdOKQflgEYpg__',
      config: [
        {
          name: '1 CPU',
        },
        {
          name: '2 GB RAM',
        },
        {
          name: '20 GB Storage',
        },
        {
          name: '20 TB Bandwidth',
        },
        {
          name: 'Intel Xeon',
        },
      ],
    },
    {
      title: 'Kafka 1st Instance',
      logoUrl:
        'https://s3-alpha-sig.figma.com/img/a93a/7fd9/0b2b2d0e4fbb437d47ff9f5363b31d15?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TYeexspjX834yWPU5PtZoNb5x4NmEuVKwOxSCDUrloeWxd5JXnTE118a8dBj821ZIMJBx1bbbxby2oKPnvjDYSWh92~2do4KfkJ31VsXTz8s7XcXIIdXPSD8rt5v2~puxaL40nwhIepdANRKdf3T~ozZnRY9wHV5kRnAG2PgRg-EawcKECEL9~XNAINNjX5iwG7~mLumiTmrSj~DbMlb6ZWv5no62S5WpBHhKJEh~boLuVY5wcdQosSC4tPMdtfHmLza8B3n8O3VV9roLSGuoKri0KslJJN3Qhr6ynQcM9l8yL-ATM-pSNT1H3q36ix7d0njltMUFVjdOKQflgEYpg__',
      config: [
        {
          name: '1 CPU',
        },
        {
          name: '2 GB RAM',
        },
        {
          name: '20 GB Storage',
        },
        {
          name: '20 TB Bandwidth',
        },
        {
          name: 'Intel Xeon',
        },
      ],
    },
    {
      title: 'Kafka 1st Instance',
      logoUrl:
        'https://s3-alpha-sig.figma.com/img/a93a/7fd9/0b2b2d0e4fbb437d47ff9f5363b31d15?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TYeexspjX834yWPU5PtZoNb5x4NmEuVKwOxSCDUrloeWxd5JXnTE118a8dBj821ZIMJBx1bbbxby2oKPnvjDYSWh92~2do4KfkJ31VsXTz8s7XcXIIdXPSD8rt5v2~puxaL40nwhIepdANRKdf3T~ozZnRY9wHV5kRnAG2PgRg-EawcKECEL9~XNAINNjX5iwG7~mLumiTmrSj~DbMlb6ZWv5no62S5WpBHhKJEh~boLuVY5wcdQosSC4tPMdtfHmLza8B3n8O3VV9roLSGuoKri0KslJJN3Qhr6ynQcM9l8yL-ATM-pSNT1H3q36ix7d0njltMUFVjdOKQflgEYpg__',
      config: [
        {
          name: '1 CPU',
        },
        {
          name: '2 GB RAM',
        },
        {
          name: '20 GB Storage',
        },
        {
          name: '20 TB Bandwidth',
        },
        {
          name: 'Intel Xeon',
        },
      ],
    },
    {
      title: 'Kafka 1st Instance',
      logoUrl:
        'https://s3-alpha-sig.figma.com/img/a93a/7fd9/0b2b2d0e4fbb437d47ff9f5363b31d15?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TYeexspjX834yWPU5PtZoNb5x4NmEuVKwOxSCDUrloeWxd5JXnTE118a8dBj821ZIMJBx1bbbxby2oKPnvjDYSWh92~2do4KfkJ31VsXTz8s7XcXIIdXPSD8rt5v2~puxaL40nwhIepdANRKdf3T~ozZnRY9wHV5kRnAG2PgRg-EawcKECEL9~XNAINNjX5iwG7~mLumiTmrSj~DbMlb6ZWv5no62S5WpBHhKJEh~boLuVY5wcdQosSC4tPMdtfHmLza8B3n8O3VV9roLSGuoKri0KslJJN3Qhr6ynQcM9l8yL-ATM-pSNT1H3q36ix7d0njltMUFVjdOKQflgEYpg__',
      config: [
        {
          name: '1 CPU',
        },
        {
          name: '2 GB RAM',
        },
        {
          name: '20 GB Storage',
        },
        {
          name: '20 TB Bandwidth',
        },
        {
          name: 'Intel Xeon',
        },
      ],
    },
  ];
  return (
    <Dashboard
      sidebarItems={sidebarItems}
      contentJsx={
        <>
          <div className="dashboard-heading">
            <div className="dashboard-header-upper-text">
              Manage Tools/Kafka
            </div>
            <div className="dashboard-header-lower-text">Kafka</div>
          </div>
          <div>
            <h5 className="tool-heading-active">Active Tools</h5>
            <Stack spacing={2} className="instance-container">
              {instanceData.map((instance, index) => (
                <ToolDetailInstance
                  key={index}
                  title={instance.title}
                  logoUrl={instance.logoUrl}
                  config={instance.config}
                />
              ))}
            </Stack>
          </div>
          <div>
            <h5 className="tool-heading-active">Inactive Tools</h5>
            <Stack spacing={2} className="instance-container">
              {instanceData.map((instance, index) => (
                <ToolDetailInstance
                  key={index}
                  title={instance.title}
                  logoUrl={instance.logoUrl}
                  config={instance.config}
                />
              ))}
            </Stack>
          </div>
        </>
      }
    />
  );
}

export default ToolsDetails;
