import React, { useRef } from 'react';
import './login.css';
import { FormControl, Grid } from '@mui/material';
import { login } from '../../api/login-api';
import { useNavigate } from 'react-router-dom';
import {
  OnboardingLayout,
  OnboardingButton,
  OnboardingTextField,
} from '../onboarding/onboarding';
import { AuthContext } from './auth-provider';
import { useNotification } from '../notification/notification-provider';
import Logo from '../common/logo';
import google from '../../assets/google-logo.svg';
// import Box from '@mui/material';
function Login() {
  const formRef = useRef();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [formError, setFormError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const { login: authContextLogin } = React.useContext(AuthContext);
  const { showNotification } = useNotification();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!email || !password) {
      setFormError(true);
      setIsLoading(false);
      return;
    }

    // Submit form data to backend
    try {
      const response = await login(email, password);
      authContextLogin(response.data);
      navigate('/organisation-list');
      showNotification('Login successful', 'success');
    } catch (error: any) {
      showNotification(error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <OnboardingLayout
        formHeight=""
        jsx={
          <div className="form-container">
            <div className="logo-container">
              <Logo />
            </div>
            <div className="login-form-content">
              <div className="login-text-container">
                <h2 className="onboarding-main-text">Login</h2>
                <p className="onboarding-sub-text">
                  Hi, Good to see you again👋
                </p>
              </div>
              <div className="sso-options-container">
                <button className="sso-options-button">
                  <img src={google} alt="" />
                  Login with Google
                </button>
              </div>
              <div className="login-container-divider">
                <span className="divider-line"></span>
                <span className="divider-or">OR</span>
                <span className="divider-line"></span>
              </div>
              <div className="login-form-container">
                <form ref={formRef} onSubmit={handleSubmit}>
                  <Grid container spacing={{ lg: 4, xl: 8 }}>
                    <Grid item xs={12}>
                      <FormControl style={{ width: '100%' }}>
                        <OnboardingTextField
                          label="Email address"
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          error={formError}
                          helperText={formError && 'Email is required'}
                          variant="standard"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl style={{ width: '100%' }}>
                        <OnboardingTextField
                          label="Password"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          error={formError}
                          helperText={formError && 'Password is required'}
                          variant="standard"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <div className="forget-password-container">
                    <a href="/forgot-password" className="forgot-password">
                      Forgot Password?
                    </a>
                  </div>
                  <div className="button-container">
                    <OnboardingButton
                      type="submit"
                      variant="contained"
                      disabled={isLoading}>
                      Continue with Email
                    </OnboardingButton>
                  </div>
                </form>
                <div className="not-member-text-container">
                  <p className="onboarding-sub-text">
                    Not a member yet?
                    <a href="/signup" className="signup-link">
                      Sign up Now
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default Login;
