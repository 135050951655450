import React from 'react';
import { useNavigate } from 'react-router-dom';
import './signup.css';
import { Stack } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { preSignup, signup, sendSignupOTP } from '../../api/signup-api';
import OTPInput from '../otp-input/otp-input';
import {
  OnboardingLayout,
  OnboardingButton,
  OnboardingTextField,
} from '../onboarding/onboarding';
import { useNotification } from '../notification/notification-provider';
import Logo from '../common/logo';
import { Constraint } from './types';

const passwordConstraintHandlerMap = {
  is_special_character_required: (password: string) => {
    return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);
  },
  is_digit_required: (password) => {
    return /[0-9]+/.test(password);
  },
  is_upper_case_required: (password) => {
    return /[A-Z]+/.test(password);
  },
  is_lower_case_required: (password) => {
    return /[a-z]+/.test(password);
  },
  min_password_length: (password, length) => {
    return password.length >= length;
  },
};

function Signup() {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = React.useState('');

  const [isLoading, setIsLoading] = React.useState(false);
  const [formPart, setFormPart] = React.useState(0);
  const [otp, setOTP] = React.useState('');
  const [passwordConstraints, setPasswordConstraints] = React.useState<
    Constraint[]
  >([]);
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  React.useEffect(() => {
    async function fetchPasswordConstraints() {
      if (passwordConstraints.length) {
        return;
      }
      try {
        const response = await preSignup();
        setPasswordConstraints(response?.data?.password_constraints || []);
      } catch (err) {
        console.log(err);
      }
    }

    fetchPasswordConstraints();
  }, [passwordConstraints]);

  const handlePasswordChange = (event) => {
    const newPasswordConstraintsToSet = passwordConstraints.map(
      (constraint: Constraint) => {
        return {
          ...constraint,
          success: passwordConstraintHandlerMap[constraint.id](
            event.target.value,
            constraint.val,
          ),
        };
      },
    );

    setPassword(event.target.value);
    setPasswordConstraints(newPasswordConstraintsToSet);
  };

  const handleGenerateOTP = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let isError = false;

    if (!email) {
      setEmailError(true);
      setIsLoading(false);
      isError = true;
    } else {
      setEmailError(false);
    }

    if (!password) {
      setPasswordError(true);
      setIsLoading(false);
      isError = true;
    } else {
      setPasswordError(false);
    }

    if (!confirmPassword) {
      setConfirmPasswordError('Confirm Password is required');
      setIsLoading(false);
      isError = true;
    } else {
      setConfirmPasswordError('');
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('Password & Confirm Password mismatch');
      setIsLoading(false);
      isError = true;
    } else {
      setConfirmPasswordError('');
    }

    if (isError) {
      return;
    }

    try {
      await sendSignupOTP(email);
      showNotification('OTP sent successfully', 'success');
      setFormPart(1);
    } catch (error: any) {
      showNotification(error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    // event.preventDefault();
    try {
      await signup(email, password, otp);
      showNotification('Sign up successfully', 'success');
      navigate('/login');
    } catch (error: any) {
      showNotification(error.message, 'error');
    }
  };

  return (
    <div>
      <OnboardingLayout
        formHeight="90vh"
        jsx={
          <div className="signup-form-container">
            <div className="org-name-container">
              <Logo />
            </div>
            {formPart === 0 ? (
              <form onSubmit={handleGenerateOTP}>
                <Stack spacing={{ xs: 1, sm: 2, md: 2, lg: 4 }}>
                  <div>
                    <h2 className="onboarding-main-text">
                      Welcome to Quicktools
                    </h2>
                    <p className="onboarding-sub-text">
                      Please create a new password since this your first time
                      logging into Quicktools
                    </p>
                  </div>
                  <div className="signup-text-fields">
                    <OnboardingTextField
                      label="Email Address"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      error={emailError}
                      helperText={emailError && 'Email is required'}
                      variant="standard"
                    />
                    <OnboardingTextField
                      label="New Password"
                      type="password"
                      value={password}
                      onChange={handlePasswordChange}
                      error={passwordError}
                      helperText={passwordError && 'Password is required'}
                      variant="standard"
                    />
                    <OnboardingTextField
                      label="Confirm Password"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      error={confirmPasswordError ? true : false}
                      helperText={confirmPasswordError}
                      variant="standard"
                    />
                  </div>
                </Stack>
                <div className="password-constraint-container">
                  {passwordConstraints.map((item) => (
                    <div className="password-constraint-item">
                      {item.success ? (
                        <CheckCircleIcon sx={{ color: 'green' }} />
                      ) : (
                        <CheckCircleIcon sx={{ color: '#E5E6EA' }} />
                      )}
                      <p className="password-constraint-item-text">
                        {item.pretty_name}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="button-container">
                  <OnboardingButton
                    type="submit"
                    variant="contained"
                    disabled={isLoading}>
                    Sign Up
                  </OnboardingButton>
                </div>
              </form>
            ) : (
              <form onSubmit={handleSubmit}>
                <Stack spacing={{ xs: 1, sm: 2, md: 3 }}>
                  <p className="org-name-text">QUICK TOOLS</p>
                  <div>
                    <ArrowBackIosNewIcon
                      fontSize="small"
                      onClick={(e) => setFormPart(0)}
                    />
                  </div>
                  <div>
                    <h2 className="onboarding-main-text">Please Enter OTP</h2>
                    <p className="onboarding-sub-text">
                      We've sent you a one time password to link to {email}
                    </p>
                  </div>
                  <OTPInput
                    length={4}
                    otp={otp}
                    setOTP={setOTP}
                    handleSubmit={handleSubmit}
                  />
                </Stack>
              </form>
            )}
          </div>
        }
      />
    </div>
  );
}

export default Signup;
