import React from 'react';
import Logo from '../common/logo'; // Assuming this is your custom logo component
import { AppBar, Toolbar, IconButton } from '@mui/material';
import DropdownWrapper from './dropdown';
import { Inbox as InboxIcon } from '@mui/icons-material';
import './header.css';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'white',
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: '5px 0px',
  minHeight: '0px',
  width: '100vw',
}));
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: '0',
  minHeight: '0px',
  padding: '0px',
}));
export const Header = function () {
  const navigate = useNavigate();
  const profileDropdownItems = [
    {
      name: 'User Profile',
      onClick: () => {
        navigate('/user/profile');
      },
    },
    { name: 'Switch Organization', onClick: () => {} },
    { name: 'Logout', onClick: () => {} },
  ];
  return (
    <StyledAppBar position="static">
      <StyledToolbar style={{ minHeight: '0px', padding: '0px 12px' }}>
        <Logo />
        <Grid container sx={{ justifyContent: 'end' }}>
          <IconButton>
            <InboxIcon sx={{ fontSize: '16px' }} />
          </IconButton>
          <DropdownWrapper
            avatar={''}
            name="Adway Lachhiramka"
            items={profileDropdownItems}
          />
        </Grid>
      </StyledToolbar>
    </StyledAppBar>
  );
};
