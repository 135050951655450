import './logo.css';
import React from 'react';
import Grid from '@mui/material/Grid';
import logo from '../../assets/logo.svg';
function Logo() {
  return (
    <Grid container sx={{ alignItems: 'center', gap: '10px' }}>
      <img src={logo} className="logo" alt="logo" />
      <p className="logo-org-name-text">QUICK TOOLS</p>
    </Grid>
  );
}

export default Logo;
