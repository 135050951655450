import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, styled, Stack, TableCell, Card, CardContent, Grid, IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import CommonTable from '../common/CommonTable';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { StyledTabsWrapper } from '../dashboard/managed-tools-dashboard';
import { createGroup, fetchGroupDetails, } from 'src/api/groups';
import Avatar from '@mui/material/Avatar';
import { generatePrettyName, getInitials, parseDate } from 'src/lib/helper';
import './grouplist.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { StyledCancelButton } from '../common/StyledCancelledButton';
import { fetchRole, fetchRoles as fetchRolesViaApi } from 'src/api/roles';
import { fetchUsers } from 'src/api/users';
import { useNotification } from '../notification/notification-provider';

const SummaryCard = ({ groupName, groupDescription, createdAt }: { groupName: string, groupDescription: string, createdAt: string }) => {
  return (
    <Card sx={{ margin: 'auto', mt: 2 }}>
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center" mb={2}>
          <Typography component="div" style={{ fontSize: '20px', fontWeight: '600' }}>
            Summary
          </Typography>
          <Button variant="outlined" size="small" sx={{
            border: '1px solid #D0D4DC',
            fontWeight: '500',
            letterSpacing: '0.02em',
            color: '#4B4EFC',
            textTransform: 'unset',
            fontSize: '15px',
            '&:hover': {
              background: '#4B4EFC',
              color: 'white',
            },
          }}>
            Edit
          </Button>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Group Name
            </Typography>
            <Typography variant="body1">
              {groupName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Creation time
            </Typography>
            <Typography variant="body1">
              {parseDate(createdAt)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Group Description
            </Typography>
            <Grid container alignItems="center">
              <Typography variant="body1" sx={{ mr: 1 }}>
                {groupDescription}
              </Typography>
              <IconButton size="small" aria-label="copy">
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const StyledButton = styled(Button)({
  background: '#4B4EFC',
  color: 'white',
  borderRadius: '8px',
  height: '50px',
  padding: '16px 25px',
  '&:hover': {
    background: '#4B4EFC',
  },
  textTransform: 'unset',
  fontSize: '15px',
  fontWeight: '500',
  letterSpacing: '0.02em',
  width: '150px',
});

const EditButton = styled(Button)({
  borderRadius: '8px',
  height: '40px',
  padding: '16px 25px',
  '&:hover': {
    background: '#4B4EFC',
    color: 'white',
  },
  border: '1px solid #D0D4DC',
  color: '#4B4EFC',
  textTransform: 'unset',
  fontSize: '15px',
  fontWeight: '500',
  letterSpacing: '0.02em',
});

interface Role {
  id: string;
  name: string;
  users: number;
  roles: number;
  description: string;
  organisation_id: string;
  created_at: string;
}

interface Person {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  created_at: string;
}

const ButtonPrettyNameMapping = {
  'Roles': 'Add Roles',
  'People': 'Add People'
};

const RemoveButtonNameMapping = {
  'Roles': 'Remove Roles',
  'People': 'Remove People'
};

function Groups() {
  const [tab, setTab] = useState('Roles');
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
    setSelectedRoles([]);
    setSelectedPeople([]);
  };
  const [groupName, setGroupName] = useState<string>('');
  const [groupDescription, setGroupDescription] = useState<string>('');
  const [roles, setRoles] = useState<Role[]>();
  const [people, setPeople] = useState<Person[]>();
  const [createdAt, setCreatedAt] = useState<string>('');
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);

  const { showNotification } = useNotification();

  const { groupId } = useParams();

  const fetchData = async () => {
    const groupDetails = await fetchGroupDetails(groupId);
    setGroupName(groupDetails.name);
    setGroupDescription(groupDetails.description);
    setRoles(groupDetails.roles);
    setPeople(groupDetails.users);
    setCreatedAt(groupDetails.created_at);

    return {
      roles: groupDetails.roles,
      users: groupDetails.users
    }

  };

  const fetchPeople = async (params) => {
    if (people) {
      return { data: people, showNextPage: false };
    }
    const { users } = await fetchData();
    return { data: users, showNextPage: false };
  }

  const fetchRoles = async (params) => {
    if (roles) {
      return { data: roles, showNextPage: false };
    }
    const { roles: rolesData } = await fetchData();
    return { data: rolesData, showNextPage: false };
  }

  const handleUserSelectionChange = (selectedRows: any[]) => {
    setSelectedPeople(selectedRows);
  }

  const handleRoleSelectionChange = (selectedRows: any[]) => {
    setSelectedRoles(selectedRows);
  }

  const handleRemoveClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmRemove = async () => {
    const dataToSend = {
      name: groupName,
      description: groupDescription,
      role_list: roles.filter((role) => !selectedRoles.find((selectedRole) => selectedRole === role.id)).map((role) => role.id),
      user_list: people.filter((person) => !selectedPeople.find((selectedPerson) => selectedPerson === person.id)).map((person) => person.id),
      id: groupId
    }

    await createGroup(dataToSend);

    setOpenDialog(false);
    if (tab === 'Roles') {
      setSelectedRoles([]);
    } else {
      setSelectedPeople([]);
    }
    navigate(`/groups`);
  };

  const handleAddClick = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const fetchAvailableRoles = async (params) => {
    const response = await fetchRolesViaApi({...params, fetch_all_roles: true});
    return { data: response.roles, showNextPage: response.showNextPage };
  };

  const fetchAvailableUsers = async (params) => {
    const response = await fetchUsers(params);
    return { data: response.data, showNextPage: response.showNextPage };
  };

  const handleAddConfirm = async () => {
    
    const dataToSend = {
      name: groupName,
      description: groupDescription,
      role_list: tab === 'Roles' ? selectedRoles : roles.map((role) => role.id),
      user_list: tab === 'People' ? selectedPeople : people.map((person) => person.id),
      id: groupId
    };
    
    await createGroup(dataToSend);

    showNotification('Group updated successfully', 'success');

    setOpenAddDialog(false);
    navigate(`/groups`);
  };


  return (
    <Box sx={{ paddingLeft: '20px', paddingTop: '10px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Box>
          <Typography sx={{ color: '#6B7281', fontWeight: '500' }}>
            User Management
          </Typography>
          <Typography sx={{ color: '#1F2937', fontWeight: '600', fontSize: '50px' }}>
            Roles and permissions
          </Typography>
        </Box>
        <Box>
          <StyledCancelButton height='50px' marginRight='15px' width='180px' isActive={selectedRoles.length > 0 || selectedPeople.length > 0} disabled={selectedRoles.length === 0 && selectedPeople.length === 0} onClick={handleRemoveClick}>
            {RemoveButtonNameMapping[tab]}
          </StyledCancelButton>
          <StyledButton onClick={handleAddClick}>
            + {ButtonPrettyNameMapping[tab]}
          </StyledButton>
        </Box>
      </Box>

      <SummaryCard groupName={groupName} groupDescription={groupDescription} createdAt={createdAt} />

      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="Tab" className="tabs-list"
            TabIndicatorProps={{ style: { background: '#4B4EFC', color: 'black' } }}>
            <StyledTabsWrapper value="Roles" label="Roles" />
            <StyledTabsWrapper value="People" label="People" />
          </TabList>
        </Box>

        <TabPanel value="Roles" sx={{ padding: '0px !important' }}>
          <CommonTable<Role>
            showSearch={false}
            showPagination={false}
            headers={[
              { label: 'Role', align: 'left' },
              { label: 'Description', align: 'center' },
              { label: 'Managed By', align: 'center' },
              { label: 'Created At', align: 'center' },
              { label: 'Actions', align: 'center' },
            ]}
            renderRow={(row: Role) => (
              <>
                <TableCell className='group-list-td table-cell' component="th" scope="row">{generatePrettyName(row.name)}</TableCell>
                <TableCell className='group-list-td table-cell center-text' component="th" scope="row">{row.description}</TableCell>
                <TableCell className='group-list-td table-cell center-text' component="th" scope="row">{row.organisation_id ? generatePrettyName(row.organisation_id) : 'System'}</TableCell>
                <TableCell className='group-list-td table-cell center-text' component="th" scope="row">{parseDate(row.created_at)}</TableCell>
                <TableCell component="th" scope="row" className='group-list-td table-cell center-text'><EditButton onClick={() => navigate(`/roles/edit/${row.id}`)}>Edit</EditButton></TableCell>
              </>
            )}
            fetchData={fetchRoles}
            searchPlaceholder="Search groups"
            rowsPerPageDefault={10}
            showCheckbox
            onSelectionChange={handleRoleSelectionChange}
          />
        </TabPanel>

        <TabPanel value="People" sx={{ padding: '0px !important' }}>
          <CommonTable<Person>
            headers={[
              { label: 'Name', align: 'left' },
              { label: 'Created At', align: 'left' },
            ]}
            showSearch={false}
            showPagination={false}
            showCheckbox
            onSelectionChange={handleUserSelectionChange}
            renderRow={(row: Person) => (
              <>
                <TableCell className='group-list-td table-cell center-text' component="th" scope="row">
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Typography sx={{ color: '#1F2937', fontWeight: '600' }}>
                      <Avatar
                        sx={{
                          width: 40,
                          height: 40,
                          fontSize: '17px',
                        }}>
                        {getInitials(row.firstname || row.email, row.lastname || '')}
                      </Avatar>
                    </Typography>
                    <Stack>
                      <Typography
                        sx={{ color: '#1F2937', fontWeight: '600', textAlign: 'left' }}>
                        {' '}
                        {(row.firstname || '') + ' ' + (row.lastname || '')}
                      </Typography>
                      <Typography
                        sx={{ color: '#6B7281', fontWeight: '500' }}>
                        {row.email}
                      </Typography>
                    </Stack>

                  </Box>
                </TableCell>
                <TableCell className='group-list-td table-cell' component="th" scope="row">{parseDate(row.created_at)}</TableCell>
              </>
            )}
            fetchData={fetchPeople}
            searchPlaceholder="Search people"
            rowsPerPageDefault={10}
          />
        </TabPanel>
      </TabContext>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Remove ${tab.toLowerCase()} from group?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            These {tab.toLowerCase()} will lose the group permissions.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} sx={{
            color: '#4B4EFC',
            fontWeight: '500',
            outline: '1px solid #D0D4DC',
          }}>Cancel</Button>
          <Button onClick={handleConfirmRemove} autoFocus sx={{
            color: '#4B4EFC',
            fontWeight: '500'
          }}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {openAddDialog ? <Dialog
        open={openAddDialog}
        onClose={handleCloseAddDialog}
        aria-labelledby="add-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="add-dialog-title">
          {`Add ${tab} to Group`} { tab === 'Roles' ? 'Roles' : 'People' }
        </DialogTitle>
        <DialogContent>
          {tab === 'Roles' ? (
            <CommonTable<Role>
              headers={[
                { label: 'Role', align: 'left' },
                { label: 'Description', align: 'center' },
                { label: 'Created At', align: 'center' },
              ]}
              showSearch={true}
              showPagination={true}
              showCheckbox={true}
              renderRow={(row: Role) => (
                <>
                  <TableCell>{generatePrettyName(row.name)}</TableCell>
                  <TableCell className='center-text'>{row.description}</TableCell>
                  <TableCell className='center-text'>{parseDate(row.created_at)}</TableCell>
                </>
              )}
              fetchData={fetchAvailableRoles}
              searchPlaceholder="Search roles"
              rowsPerPageDefault={10}
              preSelectedItems={roles.map((role) => role.id)}
              onSelectionChange={handleRoleSelectionChange}
            />
          ) : (
            <CommonTable<Person>
              headers={[
                { label: 'Name', align: 'left' },
                { label: 'Email', align: 'left' },
                { label: 'Created At', align: 'center' },
              ]}
              showSearch={true}
              showPagination={true}
              showCheckbox={true}
              renderRow={(row: Person) => (
                <>
                  <TableCell>{`${row.firstname} ${row.lastname}`}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell className='center-text'>{parseDate(row.created_at)}</TableCell>
                </>
              )}
              fetchData={fetchAvailableUsers}
              searchPlaceholder="Search users"
              rowsPerPageDefault={20}
              preSelectedItems={people.map((person) => person.id)}
              onSelectionChange={handleUserSelectionChange}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog} sx={{
            color: '#4B4EFC',
            fontWeight: '500',
            outline: '1px solid #D0D4DC',

          }}>Cancel</Button>
          <Button onClick={() => handleAddConfirm()} sx={{
            color: '#4B4EFC',
            fontWeight: '500',
            outline: '1px solid #D0D4DC',
          }}>
            Add {tab}
          </Button>
        </DialogActions>
      </Dialog> : null}
    </Box>
  );
}

export default Groups;